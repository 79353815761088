#pnlEventGrid {
  position: relative;
  margin-top: 115px;
}

section.upcoming-events.dark-bg {
  h2 {
    color: white;
    margin-top: 0;
  }

  @include respond-to($bp-large) {
    position: relative;
    z-index: 1;
    margin-top: -65px;
    border-radius: 10px;
    background: transparent url('/themes/Dallas/assets/dist/images/events-bg.png') no-repeat center center;
    background-size: cover;
    box-sizing: revert;
  }

  .event:nth-of-type(1n + 4) {
    display: none;
  }
}

.events {
  @include respond-to($bp-large) {
    display: flex;
    justify-content: space-between;
  }

  .event-details {
    font-weight: 600;
    font-size: 0.8em;

    a {
      position: relative;
      color: #e15458;
      font-weight: 600;
      font-style: italic;

      &:hover,
      &:focus {
        &:before {
          position: absolute;
          bottom: 1px;
          width: 100%;
          height: 1px;
          background-color: #e15458;
          content: '';
        }
      }

      &:visited {
        color: #b13932;
      }
    }
  }

  .gridview {
    display: none;

    @include respond-to($bp-large) {
      display: block;
      width: 100%;
      max-width: 450px;
    }

    .monthNav {
      margin-bottom: 40px;

      .icon-left-open,
      .icon-right-open {
        display: inline-block;
        width: 25px;
        height: 25px;
        border: 1px solid transparent;
        border-radius: 50%;
        background: #ffffff;
        color: #333333;

        &:before {
          line-height: 1.3em;
        }

        &:hover {
          background: #977638;
        }
      }

      .icon-left-open:before {
        margin-left: 0.05em;
      }
    }

    .monthName {
      display: inline-block;
      width: calc(100% - 65px);
      text-align: center;
      font-size: 2em;
      font-family: $fancy-font-family;
    }

    #calendarGridView {
      padding: 35px 25px;
      border: 3px solid #ffffff;
      background: #333333;

      table {
        width: 100%;
        box-shadow: none;
      }

      th {
        padding-bottom: 5px;
        font-weight: 800;
      }

      td.day {
        padding: 5px 0;
        text-align: center;
        border-right: 0;
        border-bottom: 0;

        &.has-event {
          .date {
            display: none;
          }
        }

        .day-content {
          // display: none;
        }

        &.otherMonth .date,
        &.other-month .date {
          color: #666666;
        }

        &.other-month {
          .day-content {
            display: none;
          }
        }

        a {
          font-style: normal;
        }
      }
    }
  }

  .categoryFilter {
    margin-top: -30px;
    margin-bottom: 30px;
    width: 100%;
  }
}

.listview,
.events-list-view {
  .event {
    position: relative;
    margin: 0 20px;
    padding: 25px 20px;
    border: 1px solid #dadada;
    background: #f6f6f4;

    @at-root body.homepage .listview {
      // width: 50%;

      .event {
        background: transparent;
        border: 0;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;

        h3,
        h4,
        .event-title {
          color: white;
        }
      }
    }
  }

  .blurb {
    color: #333333;
  }

  .date,
  .event-date {
    position: absolute;
    top: 20px;
    right: 20px;
    font-weight: 700;
    font-size: 0.85em;
    color: #555555;
  }
}

.events-category-name:empty {
  display: none;
}

.event-featured-image {
  display: none;
}

.events-backing-bar {
  @include respond-to($bp-large) {
    position: absolute;
    top: 50%;
    z-index: 0;
    overflow: visible;
    margin-bottom: 50px;
    width: 100vw;
    height: 410px;
    border-top: 50px solid #ffffff;
    border-bottom: 50px solid #ffffff;
    background: #b8b8b2;
    transform: translateY(-50%);
    box-sizing: revert;
  }
}

.subpage .events {
  @include respond-to($bp-large) {
    flex-wrap: wrap;
  }
}

.event {
  margin-bottom: 30px;
  color: palette(content, red-text);
  padding: 25px 20px;

  @include respond-to($bp-large) {
    margin-right: 40px;
    min-width: 450px;
    max-width: 450px;
  }

  h4,
  h3,
  .event-title {
    margin-top: 0;
    margin-right: 50px;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #575656;
    font-family: "museo-slab", serif;

    @include respond-to($bp-medium) {
      font-weight: 600;
      font-size: 1.25em;
    }
  }

  a {
    font-weight: 100;
    font-style: normal;
  }
}

.events-navigation {
  margin: 35px 0;
}