:root {
  //Login
  --login-bg: #b8b8b2;
  --login-link-color: #1a1a1a;
  --login-link-color-hover: #b13932;
  --login-text-color: #1a1a1a;
  --login-input-border: #b3b3b3;
  --login-input-border-hover: #1a1a1a;
  --login-placeholder-color: #333333;
  --login-submit-button-bg: #b13932;
  --login-submit-button-bg-hover: #1a1a1a;
  --login-submit-button-text-color: #ffffff;
  --login-submit-button-text-color-hover: #ffffff;
  --login-card-bg: #ffffff;
  --login-alert-bg: #ffffff;
  --login-logo-bg: #ffffff;
}

body.login,
body.login main {
  background-color: var(--login-bg);
  width: 100%;
  margin: 0;
  max-width: 100%;

  .version a {
    display: block;
    color: #b2b2b2;
    font-size: 16px;
    text-align: center;
  }
}

.login.custom {
  position: relative;
  padding-top: 50px;
  z-index: 1;

  &::before {
    content: '';
    position: fixed;
    background-color: var(--login-bg);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .alert {
    max-width: 450px;
    width: 100%;
    background: var(--login-alert-bg);
    padding: 10px 20px;
    margin: 0 auto 10px;
    overflow: hidden;
  }

  .align-center {
    text-align: center;
  }

  .card {
    max-width: 450px;
    width: 100%;
    background: var(--login-card-bg);
    margin: 0 auto 30px;
    overflow: hidden;
    padding: 0;

    .logo {
      background: var(--login-logo-bg);
      padding: 20px 20px 0;
      text-align: center;
      margin: auto;

      h1 {
        margin-bottom: 0;
      }

      img {
        margin: auto;
        max-width: 251px;
        height: auto;
      }
    }

    .form-fields {
      padding: 50px;
    }

    .form-group {
      margin-bottom: 25px;

      &.form-submit {
        padding-top: 5px;
        margin-bottom: 19px;
      }

      label {
        display: inline-block;
        color: var(--login-text-color);
        font-weight: 700;
        line-height: 24px;
      }

      input[type=checkbox]:checked+label:after {
        top: -1px;
      }

      input.input {
        border: 2px solid var(--login-input-border);
        width: 100%;
        padding: 15px 22px;
        height: auto;
        font-size: 14px;
        margin-bottom: 0;

        &::placeholder {
          color: var(--login-placeholder-color);
          font-weight: 700;
          opacity: .5;
        }

        &:hover,
        &:focus {
          outline: 0;
          border-color: var(--login-input-border-hover);
        }

        &:focus {
          &::placeholder {
            opacity: .25;
          }
        }
      }

      input[type="submit"] {
        padding: 13px 35px;
        margin-left: 0;
        height: auto;
        background: var(--login-submit-button-bg) !important;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 16px;
        color: var(--login-submit-button-text-color);
        letter-spacing: 1px;
        border: 0;

        &:hover,
        &:focus {
          background: var(--login-submit-button-bg-hover) !important;
          color: var(--login-submit-button-text-color-hover);
        }
      }
    }

    .cta {
      p {
        font-size: 12px;
      }
    }

    .form-cta {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;

      a {
        color: var(--login-link-color);
        text-decoration: underline;

        &:hover,
        &:focus {
          color: var(--login-link-color-hover);
        }
      }
    }
  }
}