.budget {
  padding-left: 20px;
  list-style-type: none;
  li {
    display: flex;

    flex-wrap: wrap;
    @include respond-to($bp-medium) {
      max-width: 70%;

      flex-wrap: nowrap;
    }
    justify-content: space-between;
    &.total{
      text-transform: uppercase;
      font-weight: 800;
    }
    .description {
      width: 100%;

      @include respond-to($bp-medium) {
        width: auto;
      }
    }
    .cost {
      display: block;
      width: 100%;
      text-align: right;

      @include respond-to($bp-medium) {
        display: inline;
        width: auto;
      }
    }
  }
}
