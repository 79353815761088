/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/


// Presets & Variables
@import 'grid-settings';
@import 'variables';
@import 'mixins';
@import 'helper-classes';

// Layout
@import 'layout/header';
@import 'layout/content';
@import 'layout/sidebar';
@import 'layout/footer';

//Vendor
@import 'vendor/article-columns';
@import 'vendor/article-grid';
@import 'vendor/forms';

// Components
@import 'components/accessibility';
@import 'components/general';
@import 'components/typography';
@import 'components/dallas-icons';
@import 'components/branding';
@import 'components/nav-primary';
@import 'components/nav-secondary';
@import 'components/banner';
@import 'components/images';
@import 'components/news';
@import 'components/whats-new';
@import 'components/tables';
@import 'components/events';
@import 'components/forms';
@import 'components/search';
@import 'components/login';
@import 'components/alerts';
@import 'components/sliders';
@import 'components/budget';
@import 'components/bios';
@import 'components/iebar';
@import 'components/containers';
@import 'components/accordions';
@import 'components/tabs';
@import 'components/blockquote';
@import 'components/modal';
@import 'components/login';
@import 'components/custom-login';
@import 'components/buttons';

//CTA
@import 'components/cta/cta';
@import 'components/cta/cta-banner';
@import 'components/cta/cta-billboard';
@import 'components/cta/cta-image';
@import 'components/cta/featured-boxed-item';
@import 'components/cta/highlighted-text-block';
@import 'components/cta/highlighted-text-box';
@import 'components/cta/image-content-banner';

// Vendor
@import 'vendor/font-awesome/4.2.0/scss/font-awesome';
@import 'vendor/magnific/magnific';

// Extras
@import 'shame';