body .page-content {
  position: relative;
  right: 0;
  transition: right 0.5s ease-out;
}
body.nav-open .page-content {
  height: auto;

  @include respond-to($bp-medium) {
    position: relative;
    right: 350px;
  }
  .nav-screen {
    display: block;

    @include respond-to($bp-medium) {
      display: none;
    }
  }
  .nav.primary {
    display: block;

    @include respond-to($bp-medium) {
      right: -340px;
    }
  }
}

.nav-screen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: none;
  margin: auto;
  margin-left: -8px;
  width: calc(100% + 16px);
  height: auto;
  background: #343430;
}

.nav.primary {
  position: relative;
  z-index: 1;
  display: none;
  margin: 0 -10px;
  padding: 20px 0;
  width: calc(100% + 20px);
  height: auto;
  background: transparent url('/themes/Dallas/assets/dist/images/nav-bg.png') repeat top left;

  @include respond-to($bp-medium) {
    position: absolute;
    top: 0;
    right: -345px;
    display: block;
    box-sizing: border-box;
    min-height: 100vh;
    width: 350px;
  }
  @media screen and (min-width:1140px) {
    position: static;
    right: 0;
    margin: 0 0 0 16px;
    padding: 0;
    min-height: 0;
    max-width: 100%;
    width: 100%;
    background: transparent;
    color: #333333;
  }
  &:before {
    position: absolute;
    top: -25px;
    right: 50px;
    right: 13%;
    width: 0;
    height: 0;
    border: 15px solid #575752;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    background: transparent;
    content: '';

    @include respond-to($bp-medium) {
      top: 30px;
      right: 100%;
      transform: rotate(-90deg);
    }
    @include respond-to($bp-large) {
      content: none;
    }
  }
  ul {
    margin: 0;
    padding: 0 35px;
    list-style: none;
  }
  .ul1 {
    @media screen and (min-width:1140px) {
      display: flex;
      padding: 0;
      width: calc(100% - 16px);

      justify-content: space-between;
    }
    .li1 {
      @media screen and (min-width:1140px) {
        display: inline-block;
        &:hover .ul2 {
          display: block;
        }
      }
    }
  }
  .li1:last-child .ul2{
    right: 0;
    &:before{
      right: 40px;
    }
  }
  .ul2 {
    position: relative;
    overflow: hidden;
    margin: 0 -35px;
    padding: 0;
    // padding: 35px;
    max-height: 0;
    width: 100%;
    background: #b8b8b2;

    @media screen and (min-width:1140px) {
      position: absolute;
      display: none;
      overflow: visible;
      padding: 25px 37px;
      max-height: none;
      width: 250px;
      height: auto;
      background: transparent url('/themes/Dallas/assets/dist/images/nav-bg.png') repeat top left;
    }
    &.open {
      overflow: visible;
      padding: 35px;
      max-height: 1000px;

      @include respond-to($bp-large) {
        padding: 25px 37px;
        max-height: none;
      }
    }
    &:before {
      position: absolute;
      top: -10px;
      display: block;
      width: 75px;
      height: 10px;
      background: #b8b8b2;
      content: '';

      @media screen and (min-width:1140px) {
        background: transparent url('/themes/Dallas/assets/dist/images/nav-bg.png') repeat top left;
      }
    }
    a {
      color: #292926;

      @media screen and (min-width:1140px) {
        padding: 10px 0;
        color: #ffffff;
      }
    }
  }
  a {
    display: block;
    padding: 15px 0;
    color: #ffffff;
    text-decoration: none;
    text-transform: capitalize;
    font-style: normal;

    @media screen and (min-width:1140px) {
      color: #333333;
      font-weight: 400;
      font-size: 0.9em;
    }

    &.a3 {
      font-weight: 200;
      font-style: italic;
      font-size: 0.9em;

      @media screen and (min-width:1140px) {
        padding: 8px 0;
        color: #acacac;
      }
    }
  }
}
