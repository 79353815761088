section.header {
  position: relative;
  z-index: 2;
  margin: 0 0 0 -8px;
  padding: 0;
  max-width: none;
  width: calc(100% + 16px);
  background: #ffffff;

  @include respond-to($bp-medium) {
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.15) 0 6px 0;
    transition: margin-top 0.5s ease-out;

    &.login-open {
      margin-top: 125px;

      @media screen and (min-width:1140px) {
        margin-top: 80px;
      }
    }
  }

  .header-container {
    position: relative;

    @include respond-to($bp-medium) {
      box-sizing: border-box;
      padding: 0 40px 20px;
    }

    @media screen and (min-width:1140px) {
      margin: 0 auto;
      padding: 0;
      padding-bottom: 0;
      max-width: 1100px;
      width: 100%;
    }
  }
}