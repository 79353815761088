:root {
  //Full Image Cards
  --full-image-card-text-color: white;
  --full-image-card-link-color: white;
  --full-image-card-link-color-hover: #ccc;

  .dark-mode {
    //Full Image Cards
    --full-image-card-link-color: white;
    --full-image-card-link-color-hover: #ccc;
  }
}

.cta-image {
  position: relative;
  display: inline-flex;
  min-height: 400px;
  overflow: hidden;
  border-radius: 10px;
  justify-content: center;
  align-items: flex-end;
  width: 100%;

  figure,
  &__image {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    min-height: 400px;
    margin: 0 auto;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(black, 0) 0%, rgba(black, 0.8) 100%);
      content: '';
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      object-fit: cover;
    }
  }

  .content,
  &__content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: auto;
    padding: 15px 30px;
    text-align: center;

    a {
      color: var(--full-image-card-link-color);

      &:hover,
      &:focus {
        color: var(--full-image-card-link-color-hover);
      }
    }
  }

  h2,
  h3 {
    color: var(--full-image-card-text-color);
    border: 0;
    font-size: 20px;
    line-height: 30px;

    a {
      border: 0;
    }
  }

  p {
    color: var(--full-image-card-text-color);
    font-size: 16px;
    line-height: 26px;
  }

  &[data-arx-type] {
    margin: 30px 0 0;

    .content,
    .cta-image__content {
      position: relative;
    }

    h3,
    p,
    a {
      color: black;
    }

    a {

      &:hover,
      &:focus {
        color: black;
      }
    }
  }
}