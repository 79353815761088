:root {
  //Accordions - Classic
  --accordion-bg-color: #fff;
  --accordion-title-color: #323232;
  --accordion-title-color-hover: #b73e2e;
  --accordion-title-color-focus: #0032a0;
  --accordion-content-text-color: #323232;
  --accordion-toggle-color: #323232;
  --accordion-toggle-color-hover: #b73e2e;
  --accordion-toggle-color-focus: #0032a0;
  --accordion-toggle-border-color: #323232;
  --accordion-toggle-border-color-hover: #b73e2e;
  --accordion-toggle-border-color-focus: #0032a0;
  --accordion-border-color: #e3e3e3;
  --accordion-border-color-hover: #b73e2e;
  --accordion-border-color-focus: #0032a0;

  //Accordions - Alternative
  --accordion-alt-bg-color: #e8e8eb;
  --accordion-alt-title-color: #323232;
  --accordion-alt-title-color-hover: #323232;
  --accordion-alt-content-text-color: #323232;
  --accordion-alt-toggle-color: #b73e2e;
  --accordion-alt-toggle-color-hover: #0032a0;
  --accordion-toggle-border-color: #323232;
  --accordion-toggle-border-color-hover: #323232;
  --accordion-alt-border-color: #ccc;
  --accordion-alt-border-color-hover: #323232;
}

.accordions.active {
  margin-bottom: 20px;

  .accordion:first-of-type {
    button {
      margin-top: 0;
    }
  }

  // zero out h2 if accordions are active (JS is active)
  h2.accordion-title {
    position: relative;
    padding: 0;
    margin: 0;

    &:after {
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
      width: 70px;
      height: 2px;
      background: var(--accordion-toggle-border-color);
      content: '';
    }

    &:hover,
    &:focus {
      &:after {
        background: #b73e2e;
      }
    }

    &.is-active {
      button {
        color: var(--accordion-title-color-focus);

        &:hover,
        &:focus {
          color: var(--accordion-title-color-focus);

          &::before {
            background: var(--accordion-border-color-focus);
          }
        }

        &::before {
          background: var(--accordion-border-color-focus);
        }

        &::after {
          // content: '\f106'; //caret up
          content: '\f068'; //minus
          color: var(--accordion-toggle-color-focus);
        }
      }

      &:hover,
      &:focus {
        &:after {
          background: var(--accordion-toggle-border-color-focus);
        }
      }

      &::after {
        background: var(--accordion-toggle-border-color-focus);
      }

      .accordion-content {
        padding: 0;
        margin-top: 0;
        margin-bottom: 10px;
        font-weight: 400;
        color: var(--accordion-content-text-color);
        @include fontsize(16 24);
      }

      p:last-child,
      ul:last-child,
      ol:last-child {
        margin-bottom: 0;
      }
    }

    // style just the button so that h2's maintain default styling
    button {
      position: relative;
      width: 100%;
      padding: 20px 85px 15px 0;
      margin-bottom: 0;
      color: var(--accordion-title-color);
      text-align: left;
      cursor: pointer;
      background: transparent;
      border: 0;
      font-size: 18px;

      @include medium-up {
        margin-top: 15px;
      }

      &:hover,
      &:focus {
        color: var(--accordion-title-color-hover);
        background: transparent;
        border: 0;
        outline: 0;

        &:before {
          background: var(--accordion-border-color-hover);
        }

        &:after {
          color: var(--accordion-toggle-color-hover);
        }
      }

      &:before {
        position: absolute;
        bottom: 0;
        display: block;

        // width: 100%;
        width: calc(100% - 75px);
        height: 2px;
        background: var(--accordion-border-color);
        border-radius: 50px;
        content: '';
      }

      &:after {
        // content: '\f107'; //caret down
        content: '\f067'; //plus
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: inline-flex;
        width: 70px;
        height: 25px;
        margin: auto;
        font-family: 'Font Awesome 5 Pro', 'Font Awesome 5 Free', FontAwesome;
        font-size: 16px;
        font-weight: 500;
        color: var(--accordion-toggle-color);

        justify-content: center;
      }
    }
  }

  .accordion-title+[aria-hidden] {
    display: none;
  }

  .accordion-title+[aria-hidden='false'] {
    display: block;
  }

  .accordion-content {
    padding: 15px 0;
  }
}

.accordions-alt-list {
  position: relative;

  .accordion-alt-item {
    position: relative;
    display: block;
    padding: 30px 40px;

    margin-bottom: 30px;
    background-color: var(--accordion-alt-bg-color);
    border-radius: 20px;

    // +.accordion-alt-item {
    //   border-top: 1px solid var(--accordion-alt-border-color);
    // }

    .content {
      width: 100%;

      @include large-up {
        display: inline-flex;
      }

      .image {
        width: 100%;
        margin-bottom: 30px;
        text-align: center;

        @include medium-up {
          z-index: 1;
          display: flex;
          max-width: 200px;
          min-width: 200px;
          margin-right: 40px;
          overflow: hidden;
          align-content: stretch;
          align-items: stretch;
        }

        img,
        figure {
          width: 100%;
          border-radius: 5px;

          @include medium-up {
            margin: 0;
            object-fit: cover;
            flex: 1;
          }
        }
      }

      .description {
        h2 {
          margin-top: 0;
          margin-bottom: 10px;
          color: var(--accordion-alt-title-color);
          font-size: 21px;
          line-height: 24px;
        }

        .blurb {
          p {
            margin-bottom: 20px !important;
            font-size: 16px;
            line-height: 28px;
            font-weight: 500;
            font-family: museo-slab, serif;
          }
        }

        .read-more {
          display: block;
          margin: 0;
          font-size: 13px;
          font-weight: 900;
          letter-spacing: 0.5px;
          color: var(--accordion-alt-toggle-color);
          text-transform: uppercase;
          cursor: pointer;
          background: transparent;
          border: 0;
          appearance: none;
          align-items: center;
          font-style: inherit;
          text-decoration: none;

          &:hover,
          &:focus {
            color: var(--accordion-alt-toggle-color-hover);
            border-bottom: 0;
          }

          &::before {
            content: none;
          }

          &::after {
            content: none;
          }

          span {
            font-size: 14px;
            margin-left: 5px;
          }
        }
      }
    }

    &.expanded {
      .more-alt-content {
        display: block;
      }
    }

    .more-alt-content {
      display: none;
      padding-top: 0;
      padding-bottom: 20px;

      p:last-of-type {
        margin-bottom: 0;
      }

      a.btn {
        width: 230px;
        padding: 5px 35px 5px 15px;
        margin-right: 15px;
        font-size: 16px;

        &:before {
          left: -30px;
        }

        &:hover,
        &:focus {
          &:before {
            width: calc(100% + 30px);
          }
        }
      }
    }
  }
}


//RTE Styling
.accordion[data-arx-type='accordion'],
.accordion[data-arx-type='layer'] {
  position: relative;
  margin-bottom: 15px;
  background: repeating-linear-gradient(to right, rgba(0, 88, 251, 0.04), rgba(0, 88, 251, 0.04) calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc(100% / var(--arx-grid-columns)));
  outline: 2PX dashed rgba(0, 88, 251, 0.2);
}

.accordion-preview[data-arx-type='previewaccordion'],
.accordion-alt-item[data-arx-type='imageaccordion'],
.more-alt-content[data-arx-type='accordion'],
.accordion-preview[data-arx-type='layer'],
.accordion-alt-item[data-arx-type='layer'],
.more-alt-content[data-arx-type='layer'] {
  position: relative;
  padding: 30px 10px;
  margin-bottom: 15px;
  background: repeating-linear-gradient(to right, rgba(0, 88, 251, 0.04), rgba(0, 88, 251, 0.04) calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc(100% / var(--arx-grid-columns)));
  outline: 2PX dashed rgba(0, 88, 251, 0.2);

  .image figure[data-arx-type="image"] {
    position: relative;
    padding-top: 10px;
    margin-bottom: 15px;
    background: repeating-linear-gradient(to right, rgba(0, 88, 251, 0.04), rgba(0, 88, 251, 0.04) calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc(100% / var(--arx-grid-columns)));
    outline: 2px dashed rgba(0, 88, 251, 0.2);

    @include large-up {
      padding: 40px;
      box-shadow: 0 0 25px rgba(black, 0.25);
    }

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      padding: 2px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      background: rgba(0, 88, 251, 0.04);
      border: 1px dashed rgba(0, 88, 251, 0.2);
      border-top: 0;
      border-right: 0;
      content: 'Accordion Image';
    }
  }
}

.more-alt-content[data-arx-type='accordion'],
.more-alt-content[data-arx-type='layer'] {
  padding: 30px 10px;
}