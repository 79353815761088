:root {
  //Image Content Banner
  --icb-content-bg: #1a1a1a;
  --icb-text-color: #f2f2f2;
  --icb-link: #f2f2f2;
  --icb-link-hover: #cccccc;

  //Image Content Banner - Alternate
  --icb-content-bg-secondary: #efefef;
  --icb-text-color-secondary: #1a1a1a;
  --icb-link-secondary: #1a1a1a;
  --icb-link-secondary-hover: #cccccc;

  .dark-mode {
    //Image Content Banner
    --icb-content-bg: #1a1a1a;
    --icb-text-color: #f2f2f2;
    --icb-link: #f2f2f2;
    --icb-link-hover: #cccccc;

    //Image Content Banner - Alternate
    --icb-content-bg-secondary: #efefef;
    --icb-text-color-secondary: #1a1a1a;
    --icb-link-secondary: #1a1a1a;
    --icb-link-secondary-hover: #cccccc;
  }
}

.image-content-banner {
  position: relative;
  padding: 0;
  width: 100%;
  max-width: none;
  @include large-up {
    display: inline-flex;
    overflow: hidden;
  }

  @at-root .subpage .image-content-banner{
    position: relative;
    width: 100vw;
    margin: 0 auto;
    margin-left: calc(50% - 50vw);
  }

  &--icb-left:not(.image-content-banner--solid-dark):not(.image-content-banner--solid-light) {
    .image-content-banner__text-content {
      left: 0;
      // @include large-up {
      //   left: calc(50% - 511px);
      // }
    }
  }

  &--icb-right:not(.image-content-banner--solid-dark):not(.image-content-banner--solid-light) {
    .image-content-banner__text-content {
      right: 0;
      @include large-up {
        right: -50%;
      }
    }
  }

  &--inner {
    @include large-up {
      display: inline-flex;
      align-items: center;
      width: 100%;
      max-width: 1160px;
      padding: 0 40px;
      margin: 0 auto;
    }
  }

  figure {
    position: relative;
    width: 100%;
    @include large-up {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
    }

    img {
      width: 100%;
      object-fit: cover;
      max-height: 250px;
      min-height: 250px;
      @include large-up {
        min-height: 500px;
        max-height: 100%;
        height: 100%;
      }
    }
  }

  &__text-content {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 30px 40px;
    background: #171717;
    flex-direction: column;
    justify-content: center;
    // left: 0;
    text-align: center;
    @include large-up {
      position: relative;
      top: 0;
      // left: calc(50% - 550px);
      width: 511px;
      padding: 50px 60px;
      margin-top: 0;
      min-height: 100%;
      min-height: 500px;
    }

    > * {
      color: #fff;
    }

    h2:not(.large-text),
    h3:not(.large-text) {
      text-transform: none;
      margin-top: 0;
      margin-bottom: 15px;
    }

    p {
      @include fontsize(20 30);
      margin-top: 0;
    }

    a {
      color: var(--icb-link);

      &:hover,
      &:focus {
        color: var(--icb-link-hover);
      }
    }

    a.btn {
      @include large-up {
        margin-top: 28px;
      }
    }
  }

  &--solid {
    position: relative;
    padding: 0;
    width: 100%;
    max-width: none;
    background-color: var(--icb-content-bg);
    @include large-up {
      display: inline-flex;
      align-items: center;
      // padding: 0 10%;
      overflow: hidden;
      min-height: 372px;
    }

    @at-root .subpage .image-content-banner--solid{
      position: relative;
      width: 100vw;
      margin: 0 auto;
      margin-left: calc(50% - 50vw);
    }

    &[data-arx-type] {
      figure {
        position: relative;
      }
    }

    figure {
      position: relative;
      width: 100%;
      @include large-up {
        max-width: 500px;
        height: 100%;
      }

      &[data-arx-type] {
        img {
          object-fit: contain;
          max-height: initial;
        }
      }

      img {
        width: 100%;
        object-fit: cover;
        max-height: 250px;
        min-height: 250px;
        @include large-up {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 500px;
          min-width: 425px;
          height: 100vh;
          max-height: 100%;
          min-height: 100vh;
          margin: auto;
        }
      }
    }

    &.dark {
      background-color: var(--icb-content-bg);

      &[data-arx-type] {
        background-color: var(--icb-content-bg);

        &::before {
          content: 'Solid Color Content Banner - Dark';
        }
      }
    }

    &.light {
      background-color: var(--icb-content-bg-secondary);

      &[data-arx-type] {
        background-color: var(--icb-content-bg-secondary);

        &::before {
          content: 'Solid Color Content Banner - Light';
        }
      }

      .image-content-banner__text-content {
        > * {
          color: var(--icb-text-color-secondary);
        }
      }
    }

    .image-content-banner__text-content {
      position: relative;
      background: transparent;
      width: 100%;
      padding: 30px 40px;
      text-align: left;
      left: auto;
      @include large-up {
        padding: 40px 0 40px 70px;
        max-width: 950px;
      }

      > * {
        color: var(--icb-text-color);
      }
    }

    &[data-arx-type] {
      &::before {
        content: 'Solid Color Content Banner';
      }
    }
  }

  &--solid-icb-left {
    .image-content-banner__text-content {
      left: 0;
    }
  }

  // &--solid-dark {
  //   background-color: var(--icb-content-bg);

  //   &[data-arx-type] {
  //     background-color: var(--icb-content-bg);

  //     &::before {
  //       content: 'Solid Color Content Banner - Dark';
  //     }
  //   }
  // }

  // &--solid-light {
  //   background-color: var(--icb-content-bg-secondary);

  //   &[data-arx-type] {
  //     background-color: var(--icb-content-bg-secondary);

  //     &::before {
  //       content: 'Solid Color Content Banner - Light';
  //     }
  //   }

  //   .image-content-banner__text-content {
  //     > * {
  //       color: var(--icb-text-color-secondary);
  //     }
  //   }
  // }
}

.image-content-banner[data-arx-type],
.image-content-banner--solid[data-arx-type] {
  position: relative;
  outline: rgba(0, 88, 251, .2) dashed;
  margin: 10px 0 20px !important;
  max-width: 100% !important;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    display: inline-block;
    padding: 2PX;
    font-size: 12PX;
    font-weight: 700;
    text-transform: uppercase;
    background: rgba(0, 88, 251, .04);
    border: 0.0625rem dashed rgba(0, 88, 251, .2);
    border-top: 0;
    border-right: 0;
    content: "Image Content Banner";
  }

  p {
    margin: 0 0 20px;
  }
}

.image-content-banner--solid[data-arx-type] {
  &::before {
    content: 'Solid Color Content Banner';
  }
}