section {
  margin: 0 25px;

  @include respond-to($bp-medium) {
    margin: 0 60px;
  }

  @include respond-to($bp-large) {
    margin: 0 auto;
    padding: 10px 0;
    max-width: 960px;
    width: 72%;

    &.dark-bg {
      margin: 0 auto;
    }
  }
}

.subpage {

  .page-content>.content .main {
    position: relative;

    @include respond-to($bp-large) {
      margin-right: 20px;
    }
  }

  &.full-width {
    .page-content>.content {
      max-width: 100%;
    }

    .main {
      max-width: 960px;
      width: auto;

      @include respond-to($bp-large) {
        margin-right: auto !important;

      }
    }
  }

  section {
    @include respond-to($bp-large) {
      margin: 0 auto 30px auto;
    }
  }

  .page-content>.content.content {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 auto;
    max-width: 1115px;

    @include respond-to($bp-large) {
      flex-direction: row;
    }
  }

  .banner-txt {
    position: absolute;
    top: -90px;
    color: rgba(255, 255, 255, 0.9);
    text-transform: uppercase;
  }
}