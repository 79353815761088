.nav.secondary {
  position: relative;
  display: flex;
  margin: 0 auto;
  padding: 25px;
  max-width: 100%;
  white-space: nowrap;
  font-size: 0.85em;
  align-items: flex-start;

  // justify-content: space-around;
  @include respond-to($bp-medium) {
    position: absolute;
    top: 0;
    right: 20px;
    margin: auto;
    max-width: none;
    width: calc(100% - 330px);
    font-size: 0.75em;
    justify-content: flex-end;
  }

  .new-account-container {
    display: none;

    @media screen and (min-width: 420px) {
      display: inline-block;
      padding-top: 4px;
      margin-right: 20px;

      a {
        font-size: 14px;
      }
    }
  }

  ul.social-media {
    display: none;
    margin: 0 20px 0 0;
    padding: 0;
    color: #cdcac7;
    list-style: none;
    font-size: 2em;

    @include respond-to($bp-large) {
      display: block;
    }

    li {
      display: inline-block;

      &:hover {
        cursor: pointer;

        .icon-twitter:before {
          color: #4099ff;
        }

        .icon-facebook:before {
          color: #3b599a;
        }
      }
    }
  }

  .btn {
    margin: 0 10px;
    padding: 2px 10px;
    font-weight: 500;
    font-style: normal;
    line-height: 30px;

    @include respond-to($bp-medium) {
      margin: 0;
    }

    &.search-btn {
      padding: 6px 15px;
      // vertical-align: text-top;
      border: none;
    }

    &.active {
      background: #575752;
      color: #ffffff;
      text-decoration: none;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .login-btn-container {
    display: block;
    margin-right: 10px;

    .login-btn {
      display: block;
      text-align: center;
      margin-right: 0;
    }

    .new-account {
      font-size: 14px;
      margin-top: 15px;

      @media screen and (min-width: 420px) {
        display: none;
      }

      a {
        margin-left: 13px;

        @media screen and (max-width: 1023px) and (min-width: $bp-medium) {
          display: block;
          text-align: center;
          margin-left: 0;
        }
      }
    }
  }
}

.menu-toggle {
  margin-left: 10px;
  width: 45px;
  cursor: pointer;
  position: absolute;
  right: 20px;

  @include respond-to($bp-medium) {
    position: relative;
    width: 55px;
    right: 0;
  }

  @media screen and (min-width: 1140px) {
    display: none;
  }

  .line {
    display: block;
    margin-top: 7px;
    max-width: 40px;
    width: 100%;
    height: 7px;
    background: #4e4e49;

    &.top {
      margin-top: 0;
    }
  }
}

.side-nav {
  margin-right: 50px;
  width: 100%;

  @media screen and (min-width: 1024px) {
    width: 28%;
    margin-left: 20px;
  }

  h2 {
    display: none;
  }

  .ul1 {
    margin: 19px 0 0 0;
    padding: 34px;
    background: transparent url(/themes/Dallas/assets/dist/images/nav-bg.png) repeat top left;
    list-style: none;

    li.active {
      a::before {
        position: absolute;
        top: 50%;
        left: -34px;
        width: 0;
        height: 0;
        border: 9px solid transparent;
        border-left: 15px solid #ffffff;
        content: '';
        transform: translateY(-50%);
      }
    }

    a {
      position: relative;
      display: block;
      padding: 10px 0;
      color: #ffffff;
      text-decoration: none;
      text-transform: capitalize;
      font-weight: 500;
      font-size: 19px;

      &.active:before {
        position: absolute;
        top: 50%;
        left: -34px;
        width: 0;
        height: 0;
        border: 9px solid transparent;
        border-left: 15px solid #ffffff;
        content: '';
        transform: translateY(-50%);
      }
    }
  }
}