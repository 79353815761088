:root {
  //Featured Box Item
  --fbi-bg-color: #333333;
  --fbi-text-color: white;
  --fbi-dropshadow-bg-color: white;
}

.featured-boxed-item {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  background: var(--fbi-bg-color);
  width: 100%;
  margin-bottom: 20px;
  z-index: 1;
  overflow: hidden;
  border-radius: 10px;

  @media screen and (min-width: 1024px) {
    flex-wrap: nowrap;
    margin: 0 auto 40px;
  }

  &.drop-shadow {
    background: var(--fbi-dropshadow-bg-color);
    box-shadow: 0 0 25px rgba(black, .25);
  }

  &.image-right {
    @media screen and (min-width: 1024px) {
      flex-direction: row-reverse;
    }

    .featured-boxed-item__image {
      @media screen and (min-width: 1024px) {
        left: auto;
      }
    }
  }

  &.equal-width {
    .featured-boxed-item__content {
      @media screen and (min-width: 1024px) {
        width: 50%;
      }
    }

    .featured-boxed-item__image {
      @media screen and (min-width: 1024px) {
        width: 50%;
        max-width: 50%;
      }
    }
  }

  &__content {
    display: block;
    width: 100%;
    padding: 30px 20px;
    margin: 0;

    @media screen and (min-width: 1024px) {
      width: 60%;
      padding: 60px 60px 75px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--fbi-text-color);
      margin-top: 0;
      margin-bottom: 5px;
    }

    p {
      font-size: 18px;
      line-height: 28px;
      color: var(--fbi-text-color);
      margin-top: 0;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    position: relative;
    height: 300px;
    width: 100%;

    @media screen and (min-width: 1024px) {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      max-width: 40%;
      min-height: auto;
      height: 100%;
    }

    figure {
      @media screen and (min-width: 1024px) {
        max-width: 50%;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}