// GRID SETTINGS
$column: 90px;
$gutter: 30px;
$grid-columns: 10;
$max-width: 1160px;

// BREAKPOINTS
$bp-small: 0;
$bp-medium: 720 + 'px';
$bp-large: 1024 + 'px';
$bp-xlarge: 1200 + 'px';
$bp-xxlarge: 1440 + 'px';

//OTHER BREAKPOINTS
$bp-nav-wrap: 768 + 'px';

// Breakpoints
$small: 375px;
$medium: 640px;
$large: 1024px;
$xlarge: 1440px;
$xxlarge: 1920px;

// NEAT MEDIA QUERIES
/*
$small: new-breakpoint(min-width (($bp-small) + px) 12);
$small-only: new-breakpoint(min-width (($bp-small) + px) max-width (($bp-medium - 1) + px) 12);
$medium: new-breakpoint(min-width (($bp-medium) + px) 12);
$medium-only: new-breakpoint(min-width (($bp-medium) + px) max-width (($bp-large - 1) + px) 12);
$large: new-breakpoint(min-width (($bp-large) + px) 12);
$large-only: new-breakpoint(min-width (($bp-large) + px) max-width (($bp-large - 1) + px) 12);
$xlarge: new-breakpoint(min-width (($bp-xlarge) + px) 12);
$xlarge-only: new-breakpoint(min-width (($bp-xlarge) + px) max-width (($bp-xxlarge - 1) + px) 12);
$xxlarge: new-breakpoint(min-width (($bp-xxlarge) + px) 12);
$portrait: new-breakpoint(orientation portrait);
$landscape: new-breakpoint(orientation landscape);*/
// usage @include media($small) {} or @include media($small-only) {}
