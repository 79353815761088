.banner {
  position: relative;
  margin: 0 -8px 80px;
  width: calc(100% + 16px);
  height: 380px;
  background-position: 57% -120px;
  background-size: auto 150%;
  max-height: 50vh;
  @include respond-to($bp-medium) {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
  }
  @include respond-to($bp-large) {
    margin-bottom: 80px;
    height: 460px;
  }
}

.banner-txt {
  display: none;
}

.banner-nav {
  position: absolute;
  bottom: -69px;
  left: 50%;
  margin: 0 0;
  width: calc(100% - 20px);
  transform: translateX(-50%);
  @include respond-to($bp-medium) {
    left: 0;
    margin: 0 20px;
    width: calc(100% - 40px);
    transform: none;
  }
  @include respond-to($bp-large) {
    left: 50%;
    margin: 0 auto;
    max-width: 1155px;
    transform: translateX(-50%);
  }

  .btn,
  .btn:hover {
    position: relative;
    display: block;
    margin: 0 25px;
    padding: 0;
    width: calc(100% - 50px);
    height: 100px;
    border-width: 0;
    background: transparent url('/themes/Dallas/assets/dist/images/banner-menu-bg.png') repeat top left;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    font-size: 2.25em;
    line-height: 80px;
    cursor: pointer;
    @include respond-to($bp-medium) {
      left: -6px;
      display: inline-block;
      margin: 0;
      width: calc(33% - 8px);
      height: 90px;
    }
    @include respond-to($bp-large) {
      height: 112px;
    }

    .icon-mask {
      position: relative;
      overflow: hidden;
      padding-top: 12px;
      height: 107px;
    }

    &:last-child {
      &.btn-icon:after {
        content: none;
        @include respond-to($bp-medium) {
          content: ' ';
        }
      }
    }

    &.btn-icon:after {
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      width: calc(100% - 60px);
      height: 1px;
      background: #ffffff;
      content: ' ';
      transform: translateX(-50%);
      @include respond-to($bp-medium) {
        top: 50%;
        left: 0;
        width: 1px;
        height: 70%;
        transform: translateX(0) translateY(-50%);
      }
    }

    &:first-child {
      border-radius: 15px 15px 0 0;
      @include respond-to($bp-medium) {
        margin-right: -8px;
        border-radius: 15px 0 0 15px;

        &:after {
          content: none;
        }
      }
    }

    &:last-child {
      border-radius: 0 0 15px 15px;
      @include respond-to($bp-medium) {
        margin-left: -8px;
        border-radius: 0 15px 15px 0;
      }

      &:before {
        border-bottom: 0;
      }
    }

    &:before {
      padding-bottom: 10px;
      width: calc(100% - 80px);
      border-bottom: 1px solid #ffffff;
      @include respond-to($bp-medium) {
        padding-bottom: 0;
        border-bottom: 0;
        vertical-align: middle;
      }
      @include respond-to($bp-large) {
        font-size: 1.35em;
        line-height: 1.7em;
      }
    }
  }

  .btn {
    .icon-mask img {
      margin: auto;
    }

    &.active-members .icon-mask img {
      position: relative;
      top: -138px;
      width: 165px;
      @include respond-to($bp-medium) {
        top: -153px;
        width: 175px;
      }
      @include respond-to($bp-large) {
        width: auto;
      }
    }

    &.financials .icon-mask img {
      position: relative;
      top: -128px;
      width: 165px;
      @include respond-to($bp-large) {
        top: -171px;
        width: auto;
      }
    }

    &.retired-members .icon-mask img {
      position: relative;
      top: -138px;
      width: 165px;
      @include respond-to($bp-medium) {
        top: -153px;
        width: 175px;
      }
      @include respond-to($bp-large) {
        width: auto;
      }
    }
  }

  .btn:hover {
    @include respond-to($bp-large) {
      &.active-members .icon-mask img {
        top: -25px;
      }

      &.financials .icon-mask img {
        top: -17px;
      }

      &.retired-members .icon-mask img {
        top: -12px;
      }
    }
  }
}

.subpage {
  .banner {
    top: -30px;
    margin-bottom: 10px;
    height: 115px;
    background-position: top;
    background-size: 950px auto;
    @include respond-to($bp-large) {
      background-size: cover;
    }
  }

  .banner-nav {
    display: none;
  }

  .banner-txt {
    display: none;
  }

  .alert-container {
    display: none;
  }
}
