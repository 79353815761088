/*!
*   WS Starter
*   Designed by LRS Web Solutions (http://lrswebsolutions.com)
*   Visit http://lrswebsolutions.com
*/

* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
}

*::after,
*::before {
  box-sizing: border-box;
}

html {
  overflow: hidden;
}

html,
body {
  position: relative;
  overflow-x: hidden;
  margin: 0;
  width: 100%;
  height: 100%;
  background: white;
  font-size: 100%;
}

.element-invisible {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
}

.clear {
  display: block;
  clear: both;
}

a {
  color: palette(content, link);

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover,
  &:focus {
    color: palette(content, link-hover);
    text-decoration: underline;
  }
}