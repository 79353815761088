div[class*='-slider'] {
  max-width: 100%;
  width: 100vw;

  @include respond-to($bp-large) {
    max-width: 960px;
  }
}
.cycle-slide {
  width: calc(100vw - 80px);
  vertical-align: top;

  @include respond-to($bp-medium) {
    margin: 0 40px;
    width: calc(50vw - 80px);
    &.featured {
      display: none;
    }
  }
  @include respond-to($bp-large) {
    width: calc(960px / 3.25);
  }
}

div[class*='-pager'] {
  position: absolute;
  right: 35px;
  color: #f2f2f2;
  font-size: 2em;

  -webkit-text-fill-color: #ffffff;
  -webkit-text-stroke: 1px black;
  @include respond-to($bp-medium) {
    display: none;
  }

  span {
    margin: 0 2px;
    cursor: pointer;
    &.cycle-pager-active {
      color: #977638;

      -webkit-text-fill-color: #977638;
    }
  }
}
