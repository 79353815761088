:root {
  //Blockquotes
  --bq-background: transparent;
  --bq-text: #b73e2e;
  --bq-cite-text: #585858;
  --bq-border-color: #b73e2e;
  --bq-alt-cite-text: #585858;
}

.blockquote {
  &--small {}

  &--large {}
}

blockquote:not([data-arx-type]) {
  position: relative;
  background: var(--bq-background);
  padding: 30px 0;
  margin: 30px 0;
  min-height: 140px;
  border-top: 1px solid var(--bq-border-color);
  border-bottom: 1px solid var(--bq-border-color);

  @include medium-up {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    width: 100%;
    padding: 40px 0;
    margin: 40px 0;
  }

  // &::before {
  //   content: '';
  //   position: relative;
  //   display: block;
  //   text-align: center;
  //   background-image: url('/themes/dallas/assets/dist/images/blockquote-quote.svg');
  //   background-position: 0 0;
  //   background-repeat: no-repeat;
  //   background-size: contain;
  //   width: 40px;
  //   height: 40px;
  //   margin: 0 auto 20px;

  //   @include medium-up {
  //     position: absolute;
  //     width: 80px;
  //     height: 80px;
  //     top: 30px;
  //     left: 30px;
  //   }
  // }

  &.long {
    p {
      @include large-up {
        @include fontsize(20 28);
      }
    }
  }

  // &.no-background {
  //   display: block;
  //   background: transparent;
  //   text-align: center;
  //   border-top: 1px solid var(--bq-border-color);
  //   border-radius: 0;
  //   padding: 0 0 30px;
  //   margin: 40px 0 20px;

  //   p {
  //     max-width: 916px;
  //     margin: auto;

  //     @include large-up {
  //       @include fontsize(20 28);
  //     }
  //   }

  //   cite,
  //   small {
  //     color: var(--bq-alt-cite-text);
  //   }

  //   &::before {
  //     position: relative;
  //     background-image: url('/themes/dallas/assets/dist/images/blockquote-quote-nobackground.svg');
  //     background-color: white;
  //     background-position: center;
  //     width: 45px;
  //     height: 30px;
  //     padding: 0 40px;
  //     margin: 0 auto;
  //     top: -20px;
  //     left: 0;
  //   }
  // }

  p {
    @include fontsize(18 24);
    color: var(--bq-text);
    margin: 0;
    width: 100%;
    font-weight: 500;

    @include large-up {
      @include fontsize(26 35);
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  cite,
  small {
    display: block;
    @include fontsize(12 24);
    color: var(--bq-cite-text);
    text-transform: uppercase;
    margin-top: 15px;

    @include medium-up {
      margin-top: 5px;
    }
  }
}