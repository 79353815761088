:root {
  //Highlighted Text Box
  --highlighted-text-box-bg: #1a1a1a;
  --highlighted-text-box-text-color: #ffffff;
  --highlighted-text-box-link: #ffffff;
  --highlighted-text-box-link-hover: gray;

  //Highlighted Text Box - Version 2
  --highlighted-text-box-bg-secondary: #cccccc;
  --highlighted-text-box-text-color-secondary: #1a1a1a;
  --highlighted-text-box-link-secondary: #1a1a1a;
  --highlighted-text-box-link-hover-secondary: gray;

  //Highlighted Text Box - Version 3
  --highlighted-text-box-bg-third: #edf1f2;
  --highlighted-text-box-text-color-third: #1a1a1a;
  --highlighted-text-box-link-third: #1a1a1a;
  --highlighted-text-box-link-hover-third: gray;

  .dark-mode {
    //Highlighted Text Box
    --highlighted-text-box-bg: #1a1a1a;
    --highlighted-text-box-text-color: #ffffff;
    --highlighted-text-box-link: #ffffff;
    --highlighted-text-box-link-hover: gray;

    //Highlighted Text Box - Alternate Version
    --highlighted-text-box-bg-secondary: #cccccc;
    --highlighted-text-box-text-color-secondary: #1a1a1a;
    --highlighted-text-box-link-secondary: #1a1a1a;
    --highlighted-text-box-link-hover-secondary: gray;

    //Highlighted Text Box - Version 3
    --highlighted-text-box-bg-third: #edf1f2;
    --highlighted-text-box-text-color-third: #1a1a1a;
    --highlighted-text-box-link-third: #1a1a1a;
    --highlighted-text-box-link-hover-third: gray;
  }
}

.highlighted-text-box,
.highlighted-text {
  position: relative;
  margin: 0 auto;
  border-radius: 10px;
  background-color: var(--highlighted-text-box-bg);
  padding: 40px 55px;
  @include fontsize(18 24);
  margin: 0 auto 25px;

  &.secondary-color {
    background-color: var(--highlighted-text-box-bg-secondary);

    > * {
      color: var(--highlighted-text-box-text-color-secondary);
    }
  }

  &.third-color {
    background-color: var(--highlighted-text-box-bg-third);

    > * {
      color: var(--highlighted-text-box-text-color-third);
    }
  }

  //&__content:first-of-type
  p:first-of-type {
    margin: 0;
  }

  //&__heading
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include fontsize(24 30);
    color: var(--highlighted-text-box-text-color);
  }
  //&__content
  //combine with first-of-type selector
  p,
  ul,
  ol {
    @include fontsize(16 24);
    color: var(--highlighted-text-box-text-color);
    @include medium-up {
      @include fontsize(20 30);
    }

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  //&__link
  a {
    color: var(--highlighted-text-box-link);
    text-decoration: none;

    &:hover,
    &:focus {
      color: var(--highlighted-text-box-link-hover);
    }
  }
}
