.alert-container {
  margin-right: -10px;
  padding: 10px 0 20px;
  min-height: 98px;
  background: rgba(177, 57, 50, 1);

  @include respond-to($bp-large) {
    position: absolute;
    z-index: 1;
    margin-right: 0;
    width: 100%;
    height: 98px;
    background: rgba(177, 57, 50, 0.7);
  }
}

.subpage .alert-container {
  display: none;
}

.alert {
  position: static;
  margin: 0 auto;
  max-width: 90%;
  width: 100%;
  color: #ffffff;
  text-align: left;
  font-size: 17px;
  line-height: 22px;

  @include respond-to($bp-large) {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 960px;
    transform: translateY(-50%) translateX(-50%);
  }

  h3 {
    position: relative;
    top: 19px;
    display: inline-block;
    margin: 0;
    margin-right: 30px;
    margin-bottom: 30px;
    width: 100%;
    color: #ffffff;
    letter-spacing: 1px;
    font-size: 40px;

    @include respond-to($bp-large) {
      float: left;
      width: auto;
    }
  }
  a {
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
  }
}
