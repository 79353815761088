.trustees {
  display: flex;

  flex-wrap: wrap;
}

.board-member {
  box-sizing: border-box;
  margin: 10px;
  padding: 0 20px;
  width: calc(33% - 30px);
  height: 350px;
  img {
    margin: 0;
  }
}

.team {
  display: flex;

  flex-wrap: wrap;
}

.team-member {
  box-sizing: border-box;
  margin: 10px;
  padding: 0 20px;
  width: 100%;

  @include respond-to($bp-medium) {
    margin: 30px 10px;
    width: calc(50% - 30px);
  }
  @include respond-to($bp-large) {
    width: calc(33% - 30px);
  }
  img {
    margin: 0;
  }
  p {
    margin-bottom: 2px;
  }
}

