.featured-article {
  margin-bottom: 40px;
}

.latest-news {
  margin-top: 100px;

  @include respond-to($bp-medium) {
    position: relative;
    margin-top: auto;

    h3 {
      margin-top: 10px;
    }

    .cycle-slide {
      position: relative !important;
      box-sizing: border-box;
      margin: 0 30px 0 0;
      padding: 15px;
      height: 150px;
      border-left: 5px solid #f0f0ee;

      h4 span {
        max-height: 80px;
        overflow: hidden;
        display: inline-block;
      }

      .date,
      .summary {
        display: none;
      }

      &:before,
      &:after {
        position: absolute;
        top: 0;
        left: -5px;
        width: 5px;
        height: 5px;
        border-top: 5px solid #d9d9d5;
        border-left: 5px solid #d9d9d5;
        background: transparent;
        content: ' ';
      }

      &:after {
        top: auto;
        bottom: 0;
        border-top: 0;
        border-bottom: 5px solid #d9d9d5;
      }
    }
  }

  @include respond-to($bp-large) {
    margin-bottom: 20px;
  }

  h4 {
    white-space: normal;

    @include respond-to($bp-medium) {
      margin-bottom: 10px;
    }
  }

  .date {
    color: #575656;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
  }

  .summary {
    white-space: initial;
    font-weight: 300;
    font-size: 0.8em;
    line-height: 28px;
    margin: 15px 0 30px;
  }

  a {
    font-size: 0.85em;
  }
}

.news-image {
  display: none;
  float: left;
  margin: 0 30px;

  @include respond-to($bp-large) {
    display: block;
  }
}

.homepage .news-slider.slideshow {
  display: none;
  min-height: 150px;

  @include respond-to($bp-medium) {
    display: block;
  }
}

.news-navigation {
  margin: 35px 0;

  @include respond-to($bp-medium) {
    overflow: hidden;
    height: 0;
  }

  .btn {
    display: none;
    padding: 2px 10px;
    background: #4e4e49;
    font-style: normal;
    font-size: 0.85em;

    &.red {
      display: block;
    }

    @include respond-to($bp-medium) {
      display: block;
      height: 0;
      background: transparent;
      color: transparent;

      &:hover {
        background: transparent;
        color: transparent;
      }

      .icon-left-open,
      .icon-right-open {
        position: absolute;
        bottom: 85px;
        left: -60px;
        color: #d9d9d5;
        font-size: 2em;
      }

      .icon-right-open {
        right: -60px;
        bottom: 35px;
        left: auto;
        z-index: 200;
        background: #ffffff;
        vertical-align: middle;
        line-height: 150px;
      }
    }

    @include respond-to($bp-large) {

      .icon-left-open,
      .icon-right-open {
        bottom: 135px;
      }

      .icon-right-open {
        bottom: 85px;
      }
    }
  }
}