// general
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
  color: transparent;

  &:focus,
  &:active {
    clip: auto !important;
    -webkit-clip-path: none !important;
    clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
    color: transparent;
  }
}

.element-invisible {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
}

.hide {
  display: none !important;
}

.show {
  display: initial;
}

.invisible {
  visibility: hidden;
}

.icon-text {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: '';
}

// floats
.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

// alignment
.text-left,
.align-left {
  text-align: left;
}

.text-right,
.align-right {
  text-align: right;
}

.text-center,
.align-center {
  text-align: center;
}

.text-justify,
.align-justify {
  text-align: justify;
}

.show-for-med-up {
  @include respond-to(0, $bp-medium) {
    display: none;
  }
}
