//Show/Hide Login Control For Responsiveness
.page-content>.login-container.open,
.page-content>.login-container {
    @media screen and (min-width:1140px) {
        display: block;
        opacity: 1;
    }

    display: none;
    opacity: 0;
}

.header>.login-container.open,
.header>.login-container {
    @media screen and (min-width:1140px) {
        display: none;
        opacity: 0;
    }
}

.login-container {
    @include respond-to($bp-medium) {
        position: absolute;
        top: -290px;
        left: 0;
        box-sizing: border-box;
        padding-top: 80px;
        width: 100%;
        height: 135px;
        text-align: center;
        transition: top 0.5s ease-out;
    }

    position: relative;
    display: none;
    padding: 22px 25px 0;
    background: transparent url('/themes/dallas/assets/dist/images/red-stripes.png') repeat top left;
    color: #fff;
    opacity: 0;
    transition: 0s opacity ease-in;
    transition-delay: 1s;

    &.open {
        @include respond-to($bp-medium) {
            top: -135px;
        }

        @media screen and (min-width:1140px) {
            top: -97px;
            left: 0;
            padding: 20px;
            width: 100vw;
            height: 97px;
        }

        display: block;
        opacity: 1;
    }

    .login-inner {
        display: flex;
        flex-direction: row-reverse;
        margin: -5px auto;
        max-width: 1050px;
        width: 100%;

        justify-content: space-between;
        align-items: center;
    }

    &:before {
        @include respond-to($bp-medium) {
            top: auto;
            right: calc(50% - 75px);
            bottom: -11px;
            left: auto;
            transform: rotate(180deg);
        }

        @include respond-to($bp-large) {
            right: calc(50% - 175px);
        }

        @media screen and (min-width:1140px) {
            right: calc(50% - 268px);
        }

        position: absolute;
        top: -11px;
        left: 55px;
        z-index: 10;
        width: 0;
        height: 0;
        border: 7px solid #b73e2e;
        border-top: 0;
        border-right-color: transparent;
        border-bottom: 11px solid #b73e2e;
        border-left-color: transparent;
        content: '';
    }

    .login-actions {
        @media screen and (min-width:1140px) {
            margin-right: 55px;
            padding-top: 5px;
            width: auto;
        }
    }

    input {
        @include respond-to($bp-medium) {
            margin-bottom: 0;
            width: 150px;
        }

        box-sizing: border-box;
        margin-bottom: 20px;
        padding: 7px;
        width: calc(100% - 90px);
        border: 0;
    }

    label {
        display: inline-block;
        margin-right: 5px;
        width: 79px;
        text-align: right;
        font-size: 0.6em;
    }

    a {
        color: #fff;
        text-transform: capitalize;
        font-weight: 200;
        font-style: normal;
        font-size: 12px;
        line-height: 12px;
    }

    .login-buttons {
        @include respond-to($bp-medium) {
            display: inline;
        }

        margin: 5px 0 20px auto;
        // width: calc(100% - 90px);
        text-align: right;

        a {
            text-transform: uppercase;
        }

        .btn.red {
            @include respond-to($bp-medium) {
                margin-left: 25px;
                padding: 5px 10px;
                vertical-align: bottom;
            }

            background: #791712;
            text-decoration: none;
            font-style: normal;
            font-size: 0.8em;

            &:hover {
                background: #5a110d;
                color: #fff;
            }
        }

        .register {
            @include respond-to($bp-medium) {
                display: none;
            }

            float: left;
        }
    }

    .user-buttons {
        @include respond-to($bp-medium) {
            position: absolute;
            top: 10px;
            width: 100%;
        }

        @media screen and (min-width:1140px) {
            position: static;
            width: auto;
            background: transparent;

            align-self: flex-start;
            // .forgot-p:after {
            //     display: none;
            // }
        }

        margin: 0 -25px;
        padding: 10px 25px 18px;
        width: 100%;
        background: #7e1c0d;
        color: #fff;
        text-align: center;
        // .forgot-u:after,
        // .forgot-p:after {
        //     margin: 0 5px 0 10px;
        //     content: '|';
        //     text-decoration: none;
        // }

        span.pipe {
            font-size: 12px;
            margin: 0 5px;

            @media screen and (min-width:1140px) {
                &.pass {
                    display: none;
                }
            }
        }
    }
}

.page-login {
    padding: 0 30px 30px;
    min-width: 250px;
    max-width: 350px;
    margin: auto;

    label {
        display: block;
        margin-top: 5px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
    }

    input {
        box-sizing: border-box;
        padding: 8px;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 0;
    }

    .login-buttons {
        display: flex;
        flex-direction: row-reverse;
        margin: 15px 0;

        a.register {
            margin: 0 0 0 20px;
        }
    }

    .user-buttons {
        font-size: 12px;

        a {
            font-size: 12px;
        }
    }
}