:root {
  //Highlighted Text Block
  --highlighted-text-block-bg: #e8e8eb;
  --highlighted-text-block-text-color: #323232;
  --highlighted-text-block-link: #323232;
  --highlighted-text-block-link-hover: #323232;
  --highlighted-text-block-border-color: #0032a0;

  //Highlighted Text Block - Alternate Version
  --highlighted-text-block-border-color-secondary: #b73e2e;
}

.highlighted-text,
.highlighted-text-block {
  position: relative;
  margin: 0 auto 25px;
  border-radius: 10px;
  background-color: var(--highlighted-text-block-bg);
  padding: 25px 20px;
  border-left: 30px solid var(--highlighted-text-block-border-color);

  @include medium-up {
    padding: 30px 40px;
    border-left-width: 40px;
  }

  &.secondary-color {
    border-color: var(--highlighted-text-block-border-color-secondary);
  }

  //&__heading
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 24px;
    line-height: 30px;
    color: var(--highlighted-text-block-text-color);
  }

  //&__content
  p,
  ul,
  ol {
    font-size: 16px;
    line-height: 24px;
    color: var(--highlighted-text-block-text-color);

    @include medium-up {
      font-size: 20px;
      line-height: 30px;
    }

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}