:root {
  //Card Styles
  --card-bg-color: #e8e8eb;
  --card-heading-color: #323232;
  --card-heading-underline: #818181;
  --card-text-color: #323232;
  --card-border-color: #414141;

  //Content Calls to Action (cta-banner)
  --cta-bg-color: #323232;
  --cta-text-color: white;
  --cta-button-bg-color: #323232;
  --cta-button-text-color: white;
  --cta-button-border-color: white;
  --cta-button-bg-color-hover: white;
  --cta-button-text-color-hover: #323232;
}

.card,
.card--contained,
.cta-card {
  position: relative;
  padding: 20px 40px 40px;
  overflow: hidden;
  background: var(--card-bg-color);
  border-radius: 10px;
  // box-shadow: 0 0 25px rgba(black, 0.25);

  @at-root .sidebar-layout aside .cta-card {
    height: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 5px;
    color: var(--card-heading-color);
    border: 0;
    font-size: 22px;
    line-height: 28px;

    a {
      border: 0;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 15px;
    color: var(--card-text-color);
    font-size: 18px;
    line-height: 28px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  figure {
    position: relative;
    top: -20px;
    left: -40px;
    width: calc(100% + 80px);
    max-width: calc(100% + 80px);
    margin: 0;

    img {
      width: calc(100% + 80px);
      margin: 0 auto;
    }
  }

  &__icon {
    padding-top: 40px;

    figure {
      top: 0;
      left: 0;
      width: auto;
      max-width: 100%;
      margin: 0 auto 30px;
    }
  }

  &--open {
    padding: 20px 20px 40px;
    background: transparent;
    box-shadow: none;

    figure {
      top: -20px;
      left: -20px;
      width: calc(100% + 40px);
      max-width: calc(100% + 40px);
    }

    img {
      border-radius: 10px;
    }

    >* {
      margin-right: -20px;
      margin-left: -20px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &::after {
        position: relative;
        display: block;
        width: 60px;
        height: 3px;
        margin: 20px 0 15px;
        background: var(--card-heading-underline);
        border-radius: 20px;
        content: '';
        opacity: 0.31;
      }
    }

    &__icon {
      padding-top: 40px;
      text-align: center;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        &::after {
          margin: 20px auto 15px;
        }
      }

      figure {
        top: 0;
        left: 0;
        width: auto;
        max-width: 100%;
        margin: 0 auto 30px;
      }
    }
  }

  &--mix {
    text-align: center;
    // box-shadow: 0 -15px 25px -10px rgba(black, 0.25);

    &__icon {
      padding-top: 40px;

      figure {
        top: 0;
        left: 0;
        width: auto;
        max-width: 100%;
        margin: 0 auto 30px;
      }

      &.small {
        padding: 20px 20px 15px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 17px;
          line-height: 24px;
        }

        a {
          display: block;
          width: 100%;
          border-top: 2px solid #dcdcdd;
          margin-top: 20px;
          padding-top: 5px;
          font-size: 16px;
          text-decoration: none;

          &::before {
            position: relative;
            display: inline-block;
            font-size: 16px;
            font-weight: 700;
            font-family: 'Font Awesome 5 Pro', 'Font Awesome 5 Free', FontAwesome;
            margin-right: 5px;
          }

          &[href*=".pdf"] {
            &::before {
              content: '\f1c1';
            }
          }

          &[href*=".doc"] {
            &::before {
              content: '\f1c2';
            }
          }

          &[href*=".mp4"],
          &[href*=".mp3"] {
            &::before {
              content: '\f144';
            }
          }
        }
      }
    }

  }

  &--stroke {
    border: 2px solid var(--card-border-color);
    box-shadow: none;

    figure {
      border-bottom: 2px solid var(--card-border-color);
    }
  }

  &--circle-image {
    text-align: center;
    box-shadow: none;

    figure {
      right: 0;
      left: 0;
      width: 200px;
      margin: auto;
      overflow: hidden;

      img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  &[data-arx-type] {
    figure {
      left: auto;
      width: 100%;
    }

    img {
      width: 100%;
      margin: 0 auto;
    }
  }

  &.card--circle-image {
    &[data-arx-type] {
      img {
        width: 200px;
      }
    }
  }
}