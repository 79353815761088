$base-font-size: 100%;

$rose-whte: #fffdfd;
$gray-nickel: #b8b8b2;
$star-dust: #979794;
$emperor: #555555;
$tundora: #474747;
$well-read: #b13932;
$mandy: #e15458;
$sycamore: #977638;

$primary: $star-dust;
$secondary: $well-read;
$tertiary: $sycamore;

// element colors
$palettes: (
  content: (dark-text: $emperor,
    light-text: $rose-whte,
    red-text: $mandy,
    dark-bg: $tundora,
    btn-primary: #737370,
    btn-secondary: $secondary,
    btn-dark: $tundora,
    btn-red: $well-read,
    link: $secondary,
    link-hover: $mandy,
    date-border: $well-read ),
  footer: (bg: $gray-nickel,
    txt: $tundora,
    link: $tundora )
);

@import url('https://fonts.googleapis.com/css?family=Ultra');

@font-face {
  font-family: 'Texas Hero';
  src: url('/themes/Dallas/assets/dist/fonts/Texhero.ttf');
  /* Safari, Android, iOS */
}

// typography
$text-color: palette(base, txt);
$base-font-family: 'Roboto Slab', serif;
$base-font-size: 16pt;
$base-font-weight: 500;
$base-line-height: 1.5;
$secondary-font-family: 'Ultra', serif;
$tertiary-font-family: 'Poppins', sans-serif;
$fancy-font-family: 'Texas Hero', serif;

// weights
$mod-heavy: 800;

// Get Color from Color pallette
@function palette($palette, $tone) {
  @return map-get(map-get($palettes, $palette), $tone);
}

// usage background: palette(grays, 1);