body .whats-new {
  width: auto;

  @include respond-to($bp-medium) {
    margin-top: 50px;
    margin-bottom: 50px;

    h2 .super-text {
      display: inline !important;
      color: #474747;
      text-transform: Capitalize;
      letter-spacing: 0 !important;
      font-weight: 500 !important;
      font-size: 1em !important;
      font-family: 'Ultra', serif !important;
    }
  }

  @include respond-to($bp-large) {
    position: relative;
    margin-top: 40px;
    margin-bottom: 0;
  }



  .cycle-carousel-wrap {
    white-space: revert !important;
    left: 0 !important;
  }

  h4 {
    white-space: normal;
    font-weight: 700;
    font-size: 24px;

    @include respond-to($bp-medium) {
      margin-bottom: 10px;
    }
  }

  .date {
    color: #474747;
    text-transform: uppercase;
    font-weight: 800;
  }

  .summary {
    white-space: initial;
    font-weight: 300;
    font-size: 0.8em;
    line-height: 1.25em;
    margin-bottom: 30px;
  }

  a {
    font-size: 0.85em;
  }
}

.wn-slide {
  border-top: 1px solid #cccccc;
  padding-top: 30px;

  @include respond-to($bp-medium) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.homepage .wn-slide:nth-child(1n + 4) {
  display: none;
}

.subpage {
  section.whats-new {
    margin: 0;

    h3 {
      display: none;
    }
  }

  div[class*='-slider'] {
    max-width: 100%;
  }

  .btn.whats-new {
    display: none;
  }
}

.wn-navigation {
  margin: 35px 0;

  .btn {
    padding: 2px 10px;
    // background: #4e4e49;
    font-style: normal;
    font-size: 0.85em;
    width: auto;

    @include respond-to($bp-medium) {

      &.prev-btn,
      &.next-btn {
        display: none;
      }
    }

    @include respond-to($bp-large) {
      &.btn {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}