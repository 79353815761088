:root {
  //Modal
  --modal-bg-color: #ffffff;
  --modal-text-color: #1a1a1a;
  --modal-link-color: #1a1a1a;
  --modal-link-color-hover: #414141;
  --modal-close-icon-color: #1a1a1a;

  .dark-mode {
    //Modal - Dark Mode
    --modal-bg-color: #414141;
    --modal-text-color: #ffffff;
    --modal-link-color: #1a1a1a;
    --modal-link-color-hover: #414141;
    --modal-close-icon-color: #1a1a1a;
  }
}

body.modal-open {
  overflow: hidden;
}

.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s;
  z-index: 1071;

  &__show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s;
  }
}

.modal {
  display: none;
  background-color: var(--modal-bg-color);
  width: 98vw;
  height: auto;
  min-height: calc(90vh - 40px);
  max-height: calc(90vh - 40px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  overflow: visible;
  z-index: 1072;
  border-radius: 10px;
  padding: 0 0 30px;

  @media only screen and (min-width: $medium) {
    width: 96vw;
    min-height: auto;
    height: auto;
    max-height: 90vh;
    overflow-y: visible;
  }

  @media only screen and (min-width: $large) {
    width: 60vw;
    max-width: 900px;
    min-height: auto;
    height: auto;
    max-height: 90vh;
    overflow: visible;

    &--wide {
      width: 80vw;
      max-width: 80vw;
    }
  }

  @media only screen and (min-width: $xlarge) {
    width: 50vw;
    max-width: 640px;
    min-height: auto;
    height: auto;
    max-height: 90vh;
    overflow: visible;

    &--wide {
      width: 80vw;
      max-width: 80vw;
    }
  }

  .modal-content,
  &__modal-content,
  &__content {
    padding: 31px 20px 20px;
    height: 100%;
    overflow-y: auto;
    max-height: inherit;
    text-align: center;
  }

  h2:first-of-type {
    margin-top: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul {
    color: var(--modal-text-color);
  }

  p {
    font-size: 18px;
    line-height: 24px;
  }

  a {
    font-weight: 700;
    color: var(--modal-link-color);

    &:hover,
    &:focus {
      color: var(--modal-link-color-hover);
    }
  }
}

.show-modal {
  display: block;
  opacity: 1;
  visibility: visible;
  transition: visibility 0s linear 0s, opacity 0.25s 0s;
}

.close-modal,
.modal__close-modal {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;

  &::before {
    content: '\f00d';
    font-family: 'Font Awesome 5 Pro', 'Font Awesome 5 Free', FontAwesome;
    font-weight: 900;
    font-size: 22px;
    color: var(--modal-close-icon-color);
    opacity: .5;
  }

  &:hover,
  &:focus {
    &::before {
      opacity: 1;
    }
  }
}

.modal-inline-parent {
  position: relative !important;
  z-index: auto !important;
}