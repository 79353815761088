:root {
  //Unordered Lists
  --ul-text-color: #1a1a1a;
  --ul-bullet-color: #b73e2e;
  --ul-bullet-secondary-color: #b73e2e;

  //Ordered Lists
  --ol-text-color: #1a1a1a;
  --ol-number-color: #b73e2e;
  --ol-bullet-color: #b73e2e;
}

//Base Typography
html {
  font: $base-font-size $base-font-family;
  font-weight: $base-font-weight;
  line-height: 1.25em;
}

.homepage h1:not([data-arx-type]) {
  margin: 0 -60px 60px;
  padding: 10px 75px;
  width: calc(100% + 120px);
  border: 0;
  border-bottom: 5px solid $primary;
  background: $tertiary;
  color: #ffffff;
  font-weight: 100;
  font-size: calc(#{$base-font-size} * 1.25);

  @include respond-to($bp-medium) {
    position: absolute;
    top: -200px;
    margin-bottom: 0;
    width: auto;
    border-bottom: 0;
    text-transform: uppercase;
  }
}

.subpage h1 {
  position: static;
  padding: 0 0 10px;
  // max-width: 60%;
  background: transparent;
  color: #474747;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 2.1em;
  font-family: 'Ultra', serif;
  line-height: 1.1em;
}

.subpage h2 {
  margin-top: 30px;
  border: 0;
  color: #474747;
  font-weight: 800;
  font-size: 28px;
}

.homepage h2:not([data-arx-type]) {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 0;
  // border-bottom: 2px solid palette(content, dividers);
  text-transform: capitalize;
  letter-spacing: 0;
  font-weight: 300;
  font-size: calc(#{$base-font-size} * 0.9);
}

.homepage h2:not([data-arx-type]) {
  margin-top: 30px;
  margin-bottom: 10px;
  color: #474747;
  font-weight: 500;
  font-size: 2em;
  font-family: $secondary-font-family;

  @include respond-to($bp-medium) {
    font-size: 48px;
    line-height: 34px;
  }

  .super-text {
    display: block;
    font-weight: 800;
    font-size: 0.35em;
    font-family: $base-font-family;

    @include respond-to($bp-medium) {
      letter-spacing: 4px;
      font-size: 12px;
    }
  }
}


h3:not([data-arx-type]) {
  margin-top: 0;
  margin-bottom: 20px;
  color: #333333;
  font-size: 26px;
  font-weight: 900;
}

h5:not([data-arx-type]) {
  font-size: calc(#{$base-font-size} * 0.85);
}

h6:not([data-arx-type]) {
  font-size: calc(#{$base-font-size} * 0.75);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &[data-arx-type="heading"] {
    margin: 15px 0;
  }
}

p {
  font-size: 18px;
  font-weight: 300;
  margin: 0 0 1em;

  &.small-text,
  small {
    font-size: 15px;
    line-height: 24px;
  }

  &.medium-text {
    font-size: 18px;
    line-height: 28px;

    @include respond-to($bp-medium) {
      font-size: 20px;
      line-height: 32px;
    }
  }

  &.large-text {
    font-size: 22px;
    line-height: 30px;

    @include respond-to($bp-medium) {
      font-size: 24px;
      line-height: 34px;
    }
  }
}

strong {
  font-weight: 800;
}

em {
  font-style: italic;
}

//Typography Branding

blockquote {
  font-size: $base-font-size;
}

a {
  color: palette(content, link);
  font-weight: 700;
  // font-style: italic;

  &:hover {
    color: palette(content, link-hover);
  }
}

.social-media a {
  color: $tundora;

  &:hover,
  &:focus {
    color: $mandy;
  }
}

.bordered-date {
  position: relative;
  top: -1px;
  margin: 0 10px 0 0;
  border: 1px solid palette(content, date-border);
  border-right: 0;
  border-left: 0;
  color: palette(content, date-border);
  vertical-align: middle;
  font-weight: 800;
  font-size: 0.65em;
}

[class*='icon-'].squared {
  position: relative;
  top: -2px;
  margin-right: 10px;
  padding: 1px 0;
  border: 1px solid transparent;
  border-radius: 5px;
  background: palette(content, btn-red);
  color: palette(footer, bg);
  vertical-align: middle;
  font-size: 0.7em;
}

.editable {
  font-size: 17px;

  h3 {
    margin: 15px 0 10px;
    font-size: 22px;
    line-height: 20px;
  }

  ul {
    margin: 0 20px;
    padding: 0 20px;
    // border: 1px solid #dadada;
    // background: #f6f6f4;
    list-style: none;

    &:not([style*='inline-block']) {
      margin-bottom: 40px;
    }

    &:not([class]) {
      margin: 0;
      padding-left: 20px;
      list-style: none;
      color: var(--ul-text-color);

      li {
        text-indent: -5px;

        &:before {
          position: relative;
          display: inline-block;
          font-family: 'Font Awesome 5 Pro', 'Font Awesome 5 Free', FontAwesome;
          font-size: 10px;
          font-weight: 900;
          top: -1px;
          left: -8px;
          color: var(--ul-bullet-color);
          content: '\f111';
        }

        ul {
          margin-bottom: 0;
          font-size: 16px;
          border: 0;
          list-style: disc;
          padding: 0 0 0 20px;

          li {
            &:before {
              font-size: 10px;
              color: var(--ul-bullet-secondary-color);
            }
          }
        }
      }
    }
  }

  ol:not([class]) {
    list-style: none;
    counter-reset: numbers;
    padding-left: 20px;
    margin: 0;
    color: (--ol-text-color);

    li {
      text-indent: -5px;
      counter-increment: numbers;

      &:before {
        position: relative;
        left: -8px;
        display: inline-block;
        font-weight: 600;
        content: counter(numbers);
        color: var(--ol-number-color);
      }

      >ol,
      >ul {
        margin-bottom: 0;
        font-size: 16px;

        li {
          text-indent: -5px;

          &:before {
            content: '\f111';
            font-family: 'Font Awesome 5 Pro', 'Font Awesome 5 Free', FontAwesome;
            font-weight: 900;
            display: inline-block;
            position: relative;
            font-size: 10px;
            top: -1px;
            left: -8px;
            color: var(--ol-bullet-color);
          }
        }
      }
    }
  }

  li {
    margin: 15px 0;
    color: #555555;
  }

  p {
    line-height: 30px;
    font-size: 18px;
    font-weight: 300;

    &.small-text,
    small {
      font-size: 15px;
      line-height: 24px;
    }

    &.medium-text {
      font-size: 18px;
      line-height: 28px;

      @include respond-to($bp-medium) {
        font-size: 20px;
        line-height: 32px;
      }
    }

    &.large-text {
      font-size: 22px;
      line-height: 30px;

      @include respond-to($bp-medium) {
        font-size: 24px;
        line-height: 34px;
      }
    }
  }

  hr {
    height: 1px;
    border: 0;
    background: #cccccc;
    margin: 30px 0 45px;
  }

  .two-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  .image,
  img {
    // margin: 0 30px 30px;

    &[style*='right'],
    .pull-right {
      margin-right: 0;
    }

    &[style*='left'],
    .pull-left {
      margin-left: 0;
    }

    img {
      display: block;
      width: 100%;
      // border-bottom: 1px solid #ffffff;
    }

    .caption {
      display: block;
      padding: 0.5em 1em;
      width: 100%;
      text-align: left;
      font-weight: 100;
      font-style: italic;
    }
  }
}

//Print Styles
@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: #000000 !important;
    /* Black prints faster: h5bp.com/s */
    text-shadow: none !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    /* http://css-tricks.com/almanac/properties/o/orphans/ */
    widows: 3;
    /* http://css-tricks.com/almanac/properties/w/widows/ */
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
}