.footer {
  position: relative;
  margin: 0 -10px;
  padding: 1px 35px;
  background: palette(footer, bg);
  font-size: 0.76em;
  line-height: 1.25em;

  @include respond-to($bp-medium) {
    padding: 20px 35px;
  }

  @include respond-to($bp-large) {
    padding: 1px 35px;
  }

  .content {
    flex-direction: column;

    @include respond-to($bp-medium) {
      display: flex;
      flex-direction: row;

      flex-wrap: wrap;
      justify-content: space-around;
    }

    @include respond-to($bp-large) {
      margin: 0 auto;
      padding-top: 90px;
      max-width: 1200px;

      align-content: center;
      align-items: flex-start;
    }
  }

  .content>img {
    display: none;

    @include respond-to($bp-large) {
      display: block;
    }
  }

  h3 {
    font-size: 1.75em;
    margin-top: 30px !important;
    margin-bottom: 20px !important;

    @include respond-to($bp-large) {
      margin-top: 0 !important;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    color: palette(footer, link);
    font-style: normal;
  }

  .social-media {
    margin-bottom: 30px;

    li {
      display: inline;
      color: #ffffff;
      font-size: 1.5em;
    }
  }
}

.address {
  margin-bottom: 30px;
  font-weight: 200;

  strong {
    color: palette(footer, txt);
    font-weight: 700;
    font-size: 1.2em;
  }
}

.contact {
  margin-bottom: 20px;
  font-weight: 200;
  font-weight: 700;
  font-size: 1em;

  @include respond-to($bp-medium) {
    ul {
      margin-top: 5px;
      margin-bottom: 20px;
    }
  }

  strong {
    color: palette(footer, txt);
    font-size: 1.2em;
  }

  li {
    margin-bottom: 25px;
    color: #555555;

    @include respond-to($bp-medium) {
      margin-bottom: 5px;
    }

    .label {
      font-weight: 800;
    }
  }

  a {
    margin-bottom: 25px;
    font-weight: 800;
    font-style: italic;
  }
}

.quick-links {

  ul,
  a {
    text-transform: capitalize;
    font-size: 1.05em;
    line-height: 1.5em;
  }
}

.copyright {
  white-space: nowrap;
  font-size: 0.65em;
  line-height: 1.25em;

  @include respond-to($bp-medium) {
    margin: 0 35px;
    width: 100%;
    font-size: 0.6em;

    br {
      display: none;
    }
  }

  @include respond-to($bp-large) {
    margin: 15px 0 35px 15px;
    text-indent: 115px;
  }
}