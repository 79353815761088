.search-container {
  position: relative;
  display: none;
  padding: 22px 25px;
  background: #575752;
  color: #575752;

  &.open {
    display: block;
  }

  &:before {
    position: absolute;
    top: -11px;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid #575752;
    border-top: 0;
    border-right-color: transparent;
    border-bottom: 16px solid #575752;
    border-left-color: transparent;
    content: '';
  }

  input {
    box-sizing: border-box;
    padding: 7px;
    width: 100%;
    border: 0;
  }

  span[class*='icon'] {
    position: absolute;
    top: 50%;
    right: 27px;
    font-size: 0.8em;
    transform: translateY(-50%);
  }
}

.search-btn-container {
  position: relative;
  margin-top: 0px;
  width: 140px;
  background: transparent;
  transition: width 0.5s ease-in-out, background 0.5s ease-in-out;
  padding-top: 1px;
  padding-bottom: 2px;
  height: 36px;
  border-radius: 5px;


  @include respond-to($bp-medium) {
    // background: #979794;
  }

  .icon-search {
    position: relative;
    left: 0;
    transition: left 0.5s ease-in-out;

    &.active {
      left: calc(100% - 40px);
      color: #ffffff;
    }
  }

  .search-btn {
    height: 36px;
    transition: background 0.5s ease-in-out, width 0.5s ease-in-out, color 0.5s ease-in-out;
  }

  input {
    position: absolute;
    left: 5px;
    margin-top: 6px;
    padding: 4px;
    width: 0;
    border: 0;
    background: transparent;
    color: #ffffff;
    transition: width 0.5s ease-in-out;
  }

  @include respond-to($bp-large) {
    &:hover {
      background: #575752;
      border-color: #575752;
    }
  }

  &.active {
    width: 140px;
    // background: #575752;

    input {
      width: calc(100% - 40px);
    }

    .search-btn {
      width: 160px;
      border: none;
      background: #575752;
      color: rgba(255, 255, 255, 0);
    }
  }
}