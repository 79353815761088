.columns {
  list-style: none !important;
  margin: 0;
  padding: 0;
  width: 100%;
  @include medium-up {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }
}

.col-2,
.col-3,
.col-4,
.col-5,
.col-6 {
  display: inline-block;
  margin-bottom: ($gutter * 0.5);
  width: 100%;
  @include medium-up {
    margin: 0 ($gutter * 0.5) $gutter ($gutter * 0.5);

    &[data-arx-type] {
      margin: 0 0 20px 0;
      vertical-align: top;
    }
  }

  &.vertically-center:not([data-arx-type]) {
    display: grid;
    align-content: center;
  }

  &[data-arx-type='layer'] {
    position: relative;
    margin-bottom: 15PX;
    background: repeating-linear-gradient(to right, rgba(0, 88, 251, 0.04), rgba(0, 88, 251, 0.04) calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc(100% / var(--arx-grid-columns)));
    outline: 2PX dashed rgba(0, 88, 251, 0.2);

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      padding: 2PX;
      font-size: 12PX;
      font-weight: bold;
      text-transform: uppercase;
      background: rgba(0, 88, 251, 0.04);
      border: 1px dashed rgba(0, 88, 251, 0.2);
      border-top: 0;
      border-right: 0;
      content: 'column';
    }
  }
}

.col-2 {
  @include medium-up {
    width: calc(50% - (#{$gutter} / 2));

    &:not([data-arx-type]):nth-of-type(2n+1) {
      margin-left: 0;
    }

    &:not([data-arx-type]):nth-of-type(2n) {
      margin-right: 0;
    }
  }
}

.col-3 {
  @include medium-up {
    width: calc((100% - #{$gutter}) / 2);

    &:not([data-arx-type]):nth-of-type(2n+1) {
      margin-left: 0;
    }

    &:not([data-arx-type]):nth-of-type(2n) {
      margin-right: 0;
    }
  }
  @include large-up {
    width: calc((100% - (#{$gutter} * 2)) / 3);

    &:not([data-arx-type]):nth-of-type(2n+1) {
      margin-left: ($gutter * 0.5);
    }

    &:not([data-arx-type]):nth-of-type(2n) {
      margin-right: ($gutter * 0.5);
    }

    &:not([data-arx-type]):nth-of-type(3n+1) {
      margin-left: 0;
    }

    &:not([data-arx-type]):nth-of-type(3n) {
      margin-right: 0;
    }
  }
}

.col-4 {
  @include medium-up {
    width: calc((100% - #{$gutter}) / 2);

    &:not([data-arx-type]):nth-of-type(2n+1) {
      margin-left: 0;
    }

    &:not([data-arx-type]):nth-of-type(2n) {
      margin-right: 0;
    }
  }
  @include large-up {
    width: calc((100% - (#{$gutter} * 3)) / 4);

    &:not([data-arx-type]):nth-of-type(2n+1) {
      margin-left: ($gutter * 0.5);
    }

    &:not([data-arx-type]):nth-of-type(2n) {
      margin-right: ($gutter * 0.5);
    }

    &:not([data-arx-type]):nth-of-type(4n+1) {
      margin-left: 0;
    }

    &:not([data-arx-type]):nth-of-type(4n) {
      margin-right: 0;
    }
  }
}

.col-5 {
  @include medium-up {
    width: calc((100% - #{$gutter}) / 2);

    &:not([data-arx-type]):nth-of-type(2n+1) {
      margin-left: 0;
    }

    &:not([data-arx-type]):nth-of-type(2n) {
      margin-right: 0;
    }
  }
  @include large-up {
    margin: 0 ($gutter * 0.5) ($gutter * 0.5) ($gutter * 0.5);
    width: calc((100% - (#{$gutter} * 4)) / 5);

    &:not([data-arx-type]):nth-of-type(2n+1) {
      margin-left: ($gutter * 0.5);
    }

    &:not([data-arx-type]):nth-of-type(2n) {
      margin-right: ($gutter * 0.5);
    }

    &:not([data-arx-type]):nth-of-type(5n+1) {
      margin-left: 0;
    }

    &:not([data-arx-type]):nth-of-type(5n) {
      margin-right: 0;
    }
  }
}

.col-6 {
  @include medium-up {
    margin: 0 ($gutter * 0.5) ($gutter * 0.5) ($gutter * 0.5);
    width: calc((100% - #{$gutter}) / 2);

    &:not([data-arx-type]):nth-of-type(2n+1) {
      margin-left: 0;
    }

    &:not([data-arx-type]):nth-of-type(2n) {
      margin-right: 0;
    }
  }
  @include large-up {
    margin: 0 ($gutter * 0.5) ($gutter * 0.5) ($gutter * 0.5);
    width: calc((100% - (#{$gutter} * 5)) / 6);

    &:not([data-arx-type]):nth-of-type(2n+1) {
      margin-left: ($gutter * 0.5);
    }

    &:not([data-arx-type]):nth-of-type(2n) {
      margin-right: ($gutter * 0.5);
    }

    &:not([data-arx-type]):nth-of-type(6n+1) {
      margin-left: 0;
    }

    &:not([data-arx-type]):nth-of-type(6n) {
      margin-right: 0;
    }
  }
}