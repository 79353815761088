.logo {
  margin: -10px auto 0;
  width: 90vw;

  @include respond-to($bp-medium) {
    margin: 0;
    max-width: 368px;
    width: 30vw;
  }

  img {
    width: 100%;
  }
}

.dark-bg {
  margin: 0 -10px;
  padding: 35px 35px 5px;
  background: palette(content, dark-bg);
  color: palette(content, light-text);

  @include respond-to($bp-medium) {
    padding: 45px 75px 25px;
  }
  @include respond-to($bp-large) {
    padding: 80px 100px;
  }
  h3 {
    margin-top: 0;
    color: palette(content, light-text);
  }
  h4 {
    color: palette(content, light-text);
  }
}
