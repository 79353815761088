:root {
  //Primary Button
  --button-bg-color: #737370;
  --button-bg-color-hover: #000000;
  --button-text-color: #ffffff;
  --button-text-color-hover: #ffffff;

  //Secondary Button
  --button-secondary-bg-color: #b73e2e;
  --button-secondary-bg-color-hover: #1a1a1a;
  --button-secondary-text-color: #ffffff;
  --button-secondary-text-color-hover: #ffffff;

  //Outline Button Default
  --button-outline-bg-color: transparent;
  --button-outline-bg-color-hover: transparent;
  --button-outline-text-color: #b73e2e;
  --button-outline-text-color-hover: #1a1a1a;
  --button-outline-border-color: #b73e2e;
  --button-outline-border-color-hover: #1a1a1a;

  //White Outline Button for dark backgrounds
  --button-outline-white-bg-color: transparent;
  --button-outline-white-bg-color-hover: #ffffff;
  --button-outline-white-text-color: #ffffff;
  --button-outline-white-text-color-hover: #1a1a1a;
  --button-outline-white-border-color: #ffffff;
  --button-outline-white-border-color-hover: #ffffff;

  //Arrow Buttons
  --button-arrow-color: #ffffff;
  --button-secondary-arrow-color: #1a1a1a;
  --button-arrow-bg-color: #1a1a1a;
  --button-arrow-color-hover: white;
  --button-arrow-bg-color-hover: #414141;
  --button-outline-arrow-color: #cccccc;
  --button-outline-arrow-bg-color: white;
  --button-outline-arrow-color-hover: #1a1a1a;
  --button-outline-arrow-bg-color-hover: white;
}

.btn,
a.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  border-radius: 5px;
  padding: 12px 20px;
  width: auto;
  min-width: 140px;
  overflow: hidden;

  &::before {
    content: none;
    opacity: 1;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  span:not(.afp) {
    margin-right: 5px;
    color: var(--button-color);
    font-weight: 400;
  }

  .afp {
    margin-right: 10px;
    color: currentColor;

    &--round {
      position: relative;
      left: -10px;
      margin-right: 0;
    }
  }

  &--primary,
  &--secondary {

    &__arrow-left,
    &__arrow-right {

      &::before,
      &::after {
        content: '';
        position: absolute;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: var(--button-arrow-bg-color);
        color: var(--button-arrow-color);
        font-size: 13px;
        font-family: "Font Awesome 5 Pro", "Font Awesome 5 Free", FontAwesome;
        font-weight: 900;
        top: 0;
        height: 100%;
        width: 40px;
        transition: all .25s ease-in-out;
        border-bottom: 0;
      }

      &:hover,
      &:focus {

        &::before,
        &::after {
          color: var(--button-arrow-color-hover);
          background: var(--button-arrow-bg-color-hover);
        }
      }
    }

    &__arrow-left {
      padding-left: 55px;

      &::before {
        content: '\f060';
        left: 0;
      }

      &::after {
        content: none;
      }
    }

    &__arrow-right {
      padding-right: 55px;

      &::before {
        content: none;
      }

      &::after {
        content: '\f061';
        right: 0;
      }
    }
  }

  &--primary {
    background: var(--button-bg-color);
    color: var(--button-text-color);
    border: 1px solid var(--button-bg-color);

    &:hover,
    &:focus {
      background: var(--button-bg-color-hover);
      color: var(--button-text-color-hover);
      border-color: var(--button-bg-color-hover);
    }
  }

  &--secondary {
    background: var(--button-secondary-bg-color);
    color: var(--button-secondary-text-color);
    border: 1px solid var(--button-secondary-bg-color);

    &:hover,
    &:focus {
      background: var(--button-secondary-bg-color-hover);
      color: var(--button-secondary-text-color-hover);
      border-color: var(--button-secondary-bg-color-hover);
    }

    &__arrow-left,
    &__arrow-right {

      &::before,
      &::after {
        background: rgba(#f04e25, .20);
        color: var(--button-secondary-arrow-color);
      }
    }
  }

  &--primary-pill {
    background: var(--button-bg-color);
    color: var(--button-text-color);
    border-radius: 25px;

    &:hover,
    &:focus {
      background: var(--button-bg-color-hover);
      color: var(--button-text-color-hover);
    }
  }

  &--secondary-pill {
    background: var(--button-secondary-bg-color);
    color: var(--button-secondary-text-color);
    border-radius: 25px;

    &:hover,
    &:focus {
      background: var(--button-secondary-bg-color-hover);
      color: var(--button-secondary-text-color-hover);
    }
  }

  &--primary-outline {
    background: var(--button-outline-bg-color);
    color: var(--button-outline-text-color);
    border-radius: 10px;
    border: 1px solid var(--button-outline-border-color);

    &:hover,
    &:focus {
      background: var(--button-outline-bg-color-hover);
      color: var(--button-outline-text-color-hover);
      border-color: var(--button-outline-border-color-hover);
    }

    &__arrow-left,
    &__arrow-right {

      &::before,
      &::after {
        content: '';
        position: absolute;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: var(--button-outline-arrow-bg-color);
        color: var(--button-outline-arrow-color);
        font-size: 13px;
        font-family: "Font Awesome 5 Pro", "Font Awesome 5 Free", FontAwesome;
        font-weight: 900;
        top: 0;
        height: 100%;
        width: 40px;
        transition: all .25s ease-in-out;
        border-bottom: 0;
      }

      &:hover,
      &:focus {

        &::before,
        &::after {
          color: var(--button-outline-arrow-color-hover);
          border-color: var(--button-outline-border-color-hover);
        }
      }
    }

    &__arrow-left {
      padding-left: 55px;

      &::before {
        content: '\f060';
        left: 0;
        border-right: 1px solid var(--button-outline-border-color);
      }

      &::after {
        content: none;
      }
    }

    &__arrow-right {
      padding-right: 55px;

      &::before {
        content: none;
      }

      &::after {
        content: '\f061';
        right: 0;
        border-left: 1px solid var(--button-outline-border-color);
      }
    }
  }

  &--primary-outline-white {
    background: var(--button-outline-white-bg-color);
    color: var(--button-outline-white-text-color);
    border-radius: 10px;
    border: 1px solid var(--button-outline-white-border-color);

    &:hover,
    &:focus {
      background: var(--button-outline-white-bg-color-hover);
      color: var(--button-outline-white-text-color-hover);
      border-color: var(--button-outline-white-border-color-hover);
    }
  }

  &--primary-pill-outline {
    background: var(--button-outline-bg-color);
    color: var(--button-outline-text-color);
    border-radius: 25px;
    border: 1px solid var(--button-outline-border-color);

    &:hover,
    &:focus {
      background: var(--button-outline-bg-color-hover);
      color: var(--button-outline-text-color-hover);
      border-color: var(--button-outline-border-color-hover);
    }
  }

  &--primary-pill-outline-white {
    background: var(--button-outline-white-bg-color);
    color: var(--button-outline-white-text-color);
    border-radius: 25px;
    border: 1px solid var(--button-outline-white-border-color);

    &:hover,
    &:focus {
      background: var(--button-outline-white-bg-color-hover);
      color: var(--button-outline-white-text-color-hover);
      border-color: var(--button-outline-white-border-color-hover);
    }
  }

  &--call {
    &::before {
      content: '';
    }
  }

  &--helpDesk {
    &::before {
      content: '';
    }
  }
}

.button-list {
  padding-top: 20px;
  padding-bottom: 35px;

  li {
    .btn {
      margin-bottom: 15px;
    }

    &:last-of-type {
      .btn {
        margin-bottom: 0;
      }
    }
  }
}