// pixel to rem
// @include rem-calc(10px 10px 10px 10px);

@use 'sass:math';

@function strip-unit($num) {
  @return math.div($num, $num * 0 + 1);
}

@function rem-calc($values, $base: $base-font-size) {
  $rem-values: ();
  $count: length($values);

  @if $base ==null {
    $base: 100%;
  }

  @if unit($base)=='%' {
    $base: math.div($base, 100%) * 16px;
  }

  @if $count ==1 {
    @return -ws-to-rem($values, $base);
  }

  @for $i from 1 through $count {
    $rem-values: append($rem-values, -ws-to-rem(nth($values, $i), $base));
  }

  @return $rem-values;
}

@function rc($values, $base: $base-font-size) {
  $rem-values: ();
  $count: length($values);

  @if $base ==null {
    $base: 100%;
  }

  @if unit($base)=='%' {
    $base: math.div($base, 100%) * 16px;
  }

  @if $count ==1 {
    @return -ws-to-rem($values, $base);
  }

  @for $i from 1 through $count {
    $rem-values: append($rem-values, -ws-to-rem(nth($values, $i), $base));
  }

  @return $rem-values;
}

@function -ws-to-rem($value, $base: null) {
  @if type-of($value) !='number' {
    @warn inspect($value)+'was passed to rem-calc(), which is not a number.';

    @return $value;
  }

  @if unit($value) !='rem' {
    $value: math.div(strip-unit($value), strip-unit($base)) * 1rem;
  }

  @if $value ==0rem {
    $value: 0;
  }

  @return $value;
}


// BASE TRANSITION MIXIN
@mixin transition($property: all, $duration: 0.2s, $ease: ease) {
  transition: $property $duration $ease;
}

// usage @include transition(all, 0.2s, ease);


// RESPONSIVE MEDIA QUERIES
@mixin respond-to($min: 0, $max: 0, $prop: 'width', $media: 'screen') {
  $query: '';

  @if $min  !=0 and $max  !=0 {
    $query: '(min-#{$prop}: #{$min}) and (max-#{$prop}: #{$max})';
  }

  @else if $min  !=0 and $max ==0 {
    $query: '(min-#{$prop}: #{$min})';
  }

  @else if $min ==0 and $max  !=0 {
    $query: '(max-#{$prop}: #{$max})';
  }

  @media #{$media} and #{$query} {
    @content;
  }
}

// usage @include respond-to($bp-medium) {...} or @include respond-to($max: $bp-large) {...} or @include respond-to(1100px, $prop: height} or @include respond-to($media: 'print') {...}

// CONVERT PX FONT AND LINE HEIGHTS TO REMS
@mixin font-size($sizeValue: 1, $line: $sizeValue * 1.5) {
  font-size: math.div($sizeValue, 16) + rem;
  line-height: math.div($line, 16) + rem;
}

// usage @include font-size(12, 16);


// px to rem font size and line height
// @include fontsize(45); OR @include fontsize(45 45); OR @include fontsize(45 45 40);
// the letter-spacing value is the exact value seen in the photoshop letter tracking field
@mixin fontsize($size) {
  $fontsize: nth($size, 1);
  $lineheight: $fontsize;
  $letterspacing: normal;

  @if length($size)==3 {
    $lineheight: nth($size, 2);
    $letterspacing: nth($size, 3) * 0.001+em;
  }

  @else if length($size)==2 {
    $lineheight: nth($size, 2);
    $letterspacing: normal;
  }

  @else if length($size)==1 {
    $lineheight: nth($size, 1) * 1.5;
    $letterspacing: normal;
  }

  font-size: math.div($fontsize, 1)+px;
  line-height: math.div($lineheight, 1)+px;
  font-size: math.div($fontsize, 16)+rem;
  line-height: math.div($lineheight, 16)+rem;
  letter-spacing: $letterspacing;
}

// default media queries
// @include small-only { content }
@mixin small-only {
  @media (max-width: #{$medium - 1px}) {
    @content;
  }
}

@mixin small-only {
  @media (min-width: 0) and (max-width: #{$medium - 1px}) {
    @content;
  }
}

@mixin medium-up {
  @media (min-width: #{$medium}) {
    @content;
  }
}

@mixin medium-only {
  @media (min-width: #{$medium}) and (max-width: #{$large - 1px}) {
    @content;
  }
}

@mixin large-up {
  @media (min-width: #{$large}) {
    @content;
  }
}

@mixin large-only {
  @media (min-width: #{$large}) and (max-width: #{$xlarge - 1px}) {
    @content;
  }
}

@mixin xlarge-up {
  @media (min-width: #{$xlarge}) {
    @content;
  }
}

@mixin xlarge-only {
  @media (min-width: #{$xlarge}) and (max-width: #{$xxlarge - 1px}) {
    @content;
  }
}

@mixin xxlarge-up {
  @media (min-width: #{$xxlarge}) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin width-restrict($outer-margin-mobile: 40, $outer-margin-desktop: 80, $standard-width: 1320) {
  $outer-margin-mobile: $outer-margin-mobile * 1px;
  $outer-margin-desktop: $outer-margin-desktop * 1px;
  width: $standard-width * 1px;
  max-width: calc(100% - $outer-margin-mobile);
  margin: 0 auto;

  @media all and (min-width: $medium) {
    max-width: calc(100% - $outer-margin-desktop);
  }
}